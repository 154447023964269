@import 'sass';

.Outer {
	position: relative;
	overflow: hidden;

	.Inner {
		position: inherit;

		.Column {
			@include flex-col-start;
			padding-top: 130px;
			padding-bottom: 150px;

			@media #{map-get($media, tablet_max)} {
				padding-top: 50px;
				padding-bottom: 50px !important;
			}

			.Content {
				@include reset-text-margins;
				z-index: 10;

				h1,
				h3,
				p {
					color: map-get($colors, color_primaryBlack);
					text-align: right !important;
				}

				h1 {
					@include body-hdr-default;

					// Mobile - Small
				}

				h3 {
					@include sub-default;
					color: map-get($colors, color_secondary);
				}

				p {
					@include p-default;
					margin-bottom: 25px !important;
				}

				button {
					margin-left: auto !important;
				}

				@media #{map-get($media, mobile_sm)} {

					p,
					h1,
					h3 {
						text-align: left !important;
					}

					button {
						margin-right: auto !important;
						margin-left: unset !important;
					}
				}
			}
		}

		//•••••••••••••••••••••••
		// --> Mascot Graphic
		//•••••••••••••••••••••••

		.MascotGraphic {
			position: absolute;
			z-index: 1;
			bottom: -30px;

			@media #{map-get($media, mobile)} {
				width: 330px;
			}

			@media #{map-get($media, tablet)} {
				width: 340px !important;
			}

			@media #{map-get($media, cpu_sm)} {
				width: 22vw !important;
			}

			@media #{map-get($media, cpu_m)} {
				width: 24vw !important;
			}

			@media #{map-get($media, widescreen)} {
				width: 310px !important;
			}
		}

		//••••••••••••••
		// --> Circles
		//••••••••••••••

		.CircleImage {
			position: relative;
			min-height: 300px;
			padding-left: 5%;
			z-index: 11;
			background-size: cover;
			position: center;
		}


		.Circle {
			border-radius: 100%;
			box-shadow: 7px 5px 26px rgba(0, 0, 0, 0.25);
		}

		.Circle {
			$size: 600px;
			width: $size;
			height: 0;
			padding-bottom: $size;
			position: absolute;
		}

		.CircleOne {
			top: -80px;
			right: -250px;
			z-index: 10;
			background-color: map-get($colors, color_primaryPale);
		}

		.CircleTwo {
			bottom: -120px;
			right: -320px;
			z-index: 9;
			background-color: map-get($colors, color_secondaryPale);
		}

		@media #{map-get($media, tablet_max)} {

			.CircleOne,
			.CircleTwo {
				display: none;
			}
		}
	}
}