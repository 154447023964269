@import 'sass';

.FooterWrapper {
	@include flex-col-center-center;
	padding: 0 !important;
	width: 100vw;
	color: white;

	.Grid {
		margin: 0 !important;
	}

	.Top,
	.Bottom {
		width: 100%;
	}

	.Top {
		background-color: map-get($colors, color_primaryAlt);
		padding: 30px 0;

		p {
			@include p-summary;
			padding-top: 8px;

			@media #{map-get($media, tablet_max)} {
				margin-bottom: 15px !important;
			}
		}

		h3 {
			@include sub-default;
			font-weight: 700 !important;
		}

		p,
		h3 {
			margin: 0 !important;
		}
	}

	.Bottom {
		background-color: map-get($colors, color_primaryDark);
		padding: 20px 0 30px 0;

		@media #{map-get($media, mobile)} {
			padding: 30px 0 100px 0;
		}

		.FooterBrand {


			.Logo {
				max-width: 350px !important;
			}

			@media #{map-get($media, mobile)} {
				@include flex-center-center;
			}

			@media #{map-get($media, tablet_min)} {
				@include flex-start;
			}
		}

		p {
			@include p-summary;
			margin-top: 14px;
		}

		.SocialIconWrapper {
			@include flex-center;
		}

		.InfoWrapper {
			margin-bottom: 40px;

			@media #{map-get($media, tablet_max)} {
				@include flex-center-center;
			}

			@media #{map-get($media, cpu_min)} {
				@include flex-end;
			}
		}
	}
}