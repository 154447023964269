@import 'sass';

.Container {
	position: fixed;
	z-index: 120;
	width: 100%;

	.ContainerInner {
		@include flex-center;
		top: 0;
		left: 0;
		background-color: map-get($colors, color_primaryTransp);

		.CenterLinks {
			@include flex-center-center;
			height: 100%;
		}

		.RightLinks {
			@include flex-center-center;
			height: 100%;
		}

		.CenterLinks {
			margin: 0 auto;
		}

		.RightLinks {
			position: absolute;
			right: 20px;
		}
	}

	@media #{map-get($media, tablet_max)} {
		display: none !important;
	}
}