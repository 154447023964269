@import 'sass';

.SectionWrapper {
	background-size: cover;
	background-attachment: fixed;
	position: relative;

	@media #{map-get($media, mobile)} {
		background-attachment: unset !important;
		background-position: center !important;
	}

	.Overlay {
		@include overlay-dark-brand;
	}

	.Inner {
		padding: 0px 0;
		position: relative;

		.Grid {
			position: relative;
			padding: 60px 0;
			max-width: 1000px;
			margin: 0 auto;
			height: 100% !important;
		}

		.Title h1 {
			margin: 0;
			color: white;
		}

		.Title h1:first-of-type {
			@include body-hdr-default;
			font-weight: 500;
			margin-bottom: -10px;
		}

		.Title h1:last-of-type {
			@include banner-txt-default;
			font-weight: 900;
		}

		.ReviewsWrapper {
			padding: 30px 0;
		}
	}

	.MascotGraphic {
		// Default
		width: 400px;
		position: absolute;
		bottom: 0;
		right: 0;

		// Mobile
		@media #{map-get($media, mobile)} {
			width: 300px;
			right: -140px;
			transform: scaleX(-1);
		}

		// Tablet
		@media #{map-get($media, tablet)} {
			width: 350px;
			right: -90px;
			transform: scaleX(-1);
		}
	}
}