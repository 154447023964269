@import 'sass';

.NavBranding {
	@include hover-cursor;
	position: absolute;
	left: 40px;

	img {
		background-color: whitesmoke;
		border-radius: 20px;
		padding: 15px;
		max-width: 150px !important;

	}
}